import React, { useContext, useEffect, useRef, useState } from "react";
import { MediaAuthContext } from "../store/context/media-auth-context";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";
import PG_P_RATING_REVIEW_CONTENT from "../components/pro/RatingReview/index.content";
import { PAGES } from "../helpers/constants";
import { getTrueClasses } from "../helpers";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import HomeIntro from "../components/pro/HomeIntro";
import ProProInstall from "../components/pro/ProProInstall";
import WhyAmzscout from "../components/pro/WhyAmzscout";
import UsingAmzscout from "../components/pro/UsingAmzscout";
import Profits from "../components/pro/Profits";
import CustomizableContainer from "../containers/Paywalls/Customizable/CustomizableContainer";
import Testimonials from "../components/pro/Testimonials";
import PartnerBrand from "../components/common/PartnerBrand";
import ChromeRating from "../components/pro/ChromeRating";
import RatingReview from "../components/pro/RatingReview/";
import StandardFormBlockContainer from "../containers/common/forms/StandardFormBlockContainer";
import "../components/pro/index.scss";

const PgP = () => {
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);
  const [isShowYoutubeModal, setIsShowYoutubeModal] = useState(false);
  const [urlYoutubeModal, setUrlYoutubeModal] = useState("");
  const tariffs = useRef(null);
  const { postData } = PG_P_RATING_REVIEW_CONTENT;
  const closeYoutubeModal = () => setUrlYoutubeModal("");

  useEffect(() => {
    setIsShowYoutubeModal(!!urlYoutubeModal);
  }, [urlYoutubeModal]);

  return (
    <Layout
      tariffsElement={tariffs}
      closeYoutubeModal={closeYoutubeModal}
      urlYoutubeModal={urlYoutubeModal}
      isShowYoutubeModal={isShowYoutubeModal}
      customClass="PgP"
    >
      <Seo
        title="AMZScout PRO Extension - Try for Free"
        description="With the powerful AMZScout PRO Extension, access sales volume, price history, competition score, and other statistics on every Amazon product right in your browser. Start your free trial."
        page="pro"
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <HomeIntro />
      <ChromeRating />
      <PartnerBrand />
      <RatingReview excellentText="Excellent" posts={postData} />
      <UsingAmzscout />
      <StandardFormBlockContainer
        buttonTextFirstState="Try PRO Extension For Free"
        buttonTextSecondState="Try PRO Extension For Free!"
        page={PAGES.PRO}
        customClass={getTrueClasses(
          isAuth && "PgP-standardFormPageBlock-secondState"
        )}
        title="Find products that will bring you $5,000/mo or more with a PRO Extension FREE TRIAL!"
      />
      <CustomizableContainer pricingRef={tariffs} subTitlePro />
      <ProProInstall />
      <Profits />
      <WhyAmzscout />
      <StandardFormBlockContainer
        buttonTextFirstState="Try PRO Extension For Free"
        buttonTextSecondState="Try PRO Extension For Free!"
        page={PAGES.PRO}
        customClass={
          isAuth
            ? "PgP-standardFormPageBlock-secondState"
            : "PgP-standardFormPageBlock-firstState"
        }
        title="Enter your email to start your FREE TRIAL!"
      />
      <Testimonials setUrlYoutubeModal={setUrlYoutubeModal} />
    </Layout>
  );
};

export default PgP;
